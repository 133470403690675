








import Vue from 'vue'

import Routes from '@/constants/routes'

import { MenuItem } from "@happytal/bo-ui-library/src/types"

import { HSecondaryNav } from '@happytal/bo-ui-library'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'

export default Vue.extend({
  components: {
    ResidentHeader,
    HSecondaryNav
  },

  data() {
    return {
      parentSelectedRoute: Routes.ResidentSuggestions,
      selectedRouteName: this.$route.name,
    }
  },

  watch: {
    "$route"() {
      this.selectedRouteName = this.$route.name
    }
  },

  computed: {
    currentResident(): string {
      return this.$store.getters['dataResidents/getResidentById'](this.$route.params.resident_id)
    },
    internalMenuItems(): MenuItem[] {
      const result: MenuItem[] = []
      const tabSuggestionsPending: MenuItem = {
        title: 'En attente de réponse',
        icon: 'HistoryIcon',
        route: {
          name: Routes.ResidentSuggestionsPending
        }
      }
      const tabSuggestionsToProcess: MenuItem = {
        title: 'À traiter',
        icon: 'ClipboardCheckIcon',
        route: {
          name: Routes.ResidentSuggestionsToProcess
        }
      }
      const tabSuggestionsHistory: MenuItem = {
        title: 'Historique',
        icon: 'ClipboardListIcon',
        route: {
          name: Routes.ResidentSuggestionsHistory
        }
      }

      if (this.$aclChecker.some(this.$acl, [ 'isHappytalEmployee', 'isHappytalSupport'])) {
        result.unshift(tabSuggestionsPending, tabSuggestionsToProcess)
      }
      else if (this.$aclChecker.some(this.$acl, [ 'isEhpadEmployee', 'isEhpadManager'])) {
        result.unshift(tabSuggestionsPending)
      }
      else {
        result.unshift(tabSuggestionsToProcess)
      }

      result.push(tabSuggestionsHistory)
      
      return result
    }
  },

  created() {
    this.$store.dispatch('dataSuggestions/fetchAllSuggestions', this.$route.params.resident_id)
  },
})
